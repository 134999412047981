import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "../src/App";
import { Provider } from "react-redux";
import store from "../src/Redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Suspense } from "react";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Loader from "./CommanUIComp/Loader/Loader";
const persistStore1 = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate persistor={persistStore1}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </Suspense>
  </>
);

reportWebVitals()

