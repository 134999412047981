import "./pageUnderConstruction.scss"

const PageUnderConstruction = () => {
    
    return (
        <div className="pageUnderConstruction title">
            <span className="title">    The Site is Under Maintaince</span>
        </div>
    )
}

export default PageUnderConstruction