import React, { useEffect } from "react"
import { useNavigate } from "react-router"

const DashRouterPage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const storeUrl = sessionStorage.getItem("storeUrl") !== null ? sessionStorage.getItem("storeUrl") : "/";
        navigate(storeUrl)
    }, [navigate]);
    return (<React.Fragment></React.Fragment>)
}
export default DashRouterPage;