import React from 'react';

const CrispChat = () => {

    const handleScript = () => {
        const d = document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s)
    }

    return <script type="text/javascript">
        {window.$crisp = []}
        {window.CRISP_WEBSITE_ID = "b9bb22ac-2209-4f65-8640-f44417e00bf1"}
        {handleScript()}
    </script>;
};

export default CrispChat;
