import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {

    const selector = useSelector(state => state)

    const isLoggedIn = Object.keys(selector.loginData).length > 0

    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }
    return children;
};
export default ProtectedRoute;