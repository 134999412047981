import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import './campaignPreview.scss';
import { isEmpty } from "../../CommanFunctions/commanFunctions";

const CampaignPreview = () => {

    const params = useParams();
    const [url, setUrl] = useState("");
    const selector = useSelector(state => state)

    useEffect(() => {
        const urls = "https://" + isEmpty(selector.storeEntityId.origin) + "/multiple-template/?campaign_id=" + params.campaign_id + "&un_id=" + isEmpty(params.optional_param) + '&customer_id=&type=preview' + "&is_customer=0&user_type=consumer";
        setUrl(urls)
    }, [params]);

    return (
        <React.Fragment>
            <div className="iframe-ht-cust">
                <iframe id="iframe" src={url}></iframe>
            </div>
        </React.Fragment>
    )
}

export default CampaignPreview;