import { createStore, applyMiddleware } from "redux";
import Reducer from "./reducer";
import ReduxThunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-key",
  storage,
};

const persistReducer1 = persistReducer(persistConfig, Reducer);
const Store = createStore(persistReducer1, applyMiddleware(ReduxThunk));

export default Store;
